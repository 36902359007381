<template>
  <div class="question column" :class="width">
    <div class="question-content card">
      <div class="card-content" v-html="decodeHtml(question.question[lang])" v-if="isQuotaFull"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'
import { quotaFullDetail } from '@/api/response'

export default {
  name: 'QuotaFull',
  mixins: [QuestionMixin],
  data () {
    return {
      isQuotaFull: false,
      surveyCode: this.$route.params?.code,
      resultValue: 'N',
      width: 'is-8'
    }
  },
  methods: {
    async checkQuotaFull() {
      const targetQuota = this.question?.options?.quota
      const {data: {data: {completed: completed}}} = await quotaFullDetail(this.surveyCode, this.question?.qid)
      this.$emit("input", { [this.question.qid]: (completed >= targetQuota) ? 'Y': 'N' });
      if(completed >= targetQuota){
        this.isQuotaFull = true
        this.$emit("quota-full");
      }
      this.$emit('submit')
    }
  },
  async created() {
    await this.checkQuotaFull()
  }
}
</script>
